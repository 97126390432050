.pdf-viewer-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.pdf-content {
  height: 100%;
  width: 100%;
}

.floating-analyze-button {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  max-width: 80%;
  animation: slideUp 0.3s ease-out;
}

.analyze-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 1rem;
}

.analyze-button:hover:not(:disabled) {
  background-color: #1557b0;
}

.analyze-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.selected-preview {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  font-style: italic;
  max-width: 100%;
  word-break: break-word;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

.analyze-button-small {
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}

.analyze-button-small:hover {
  background-color: #1557b0;
}

.temp-analyze-button {
  background: white;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: fixed;
  transform: translate(10px, 10px); /* Offset from cursor */
  pointer-events: auto;
}

.streaming-analysis {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  font-size: 0.9rem;
  line-height: 1.5;
  white-space: pre-wrap;
  max-height: 400px;
  overflow-y: auto;
  animation: fadeIn 0.3s ease-out;
  width: 100%;
  max-width: 600px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.markdown-content {
  color: #2c3e50;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-weight: 600;
  color: #1a73e8;
}

.markdown-content h1 {
  font-size: 1.5em;
}

.markdown-content h2 {
  font-size: 1.3em;
}

.markdown-content h3 {
  font-size: 1.1em;
}

.markdown-content p {
  margin: 0.8em 0;
  line-height: 1.6;
}

.markdown-content ul,
.markdown-content ol {
  margin: 0.5em 0;
  padding-left: 1.5em;
}

.markdown-content li {
  margin: 0.3em 0;
}

.markdown-content code {
  background-color: #f0f2f5;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
  font-size: 0.9em;
}

.markdown-content pre {
  background-color: #f0f2f5;
  padding: 1em;
  border-radius: 4px;
  overflow-x: auto;
}

.markdown-content blockquote {
  border-left: 4px solid #1a73e8;
  margin: 1em 0;
  padding-left: 1em;
  color: #666;
}

.markdown-content a {
  color: #1a73e8;
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

.help-button-container {
  background: white;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transform: translate(8px, -50%);
}

.help-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.2s;
}

.help-button:hover {
  background-color: #1557b0;
}

.help-button svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
}

.language-selector {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
}

.language-select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  font-size: 0.9rem;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.language-select:hover {
  border-color: #1a73e8;
}

.language-select:focus {
  outline: none;
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
} 