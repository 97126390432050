.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.landing-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
  padding: 1rem;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.title {
  font-size: clamp(2rem, 5vw, 3rem);
  color: #1a73e8;
  text-align: center;
  margin-bottom: 2rem;
}

.hero-section {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 2rem 1rem;
}

.hero-section h1 {
  font-size: 3rem;
  color: #ff4b8b;
  margin-bottom: 1rem;
  font-weight: 700;
}

.tagline {
  font-size: 1.5rem;
  color: #4a5568;
  margin-bottom: 3rem;
  font-style: italic;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.feature {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
  border: 1px solid #ffe4ed;
}

.feature:hover {
  transform: translateY(-5px);
  border-color: #ff4b8b;
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  display: block;
}

.feature h3 {
  color: #ff4b8b;
  margin-bottom: 0.5rem;
}

.feature p {
  color: #718096;
  line-height: 1.5;
}

.upload-section {
  text-align: center;
  margin: 2rem auto;
  max-width: min(600px, 90%);
  padding: clamp(1rem, 5vw, 2rem);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}

.upload-section h2 {
  font-size: clamp(1.5rem, 4vw, 2rem);
  color: #ff4b8b;
  margin-bottom: 1rem;
}

.upload-section p {
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: #666;
  margin-bottom: 2rem;
}

.file-input {
  display: none;
}

.file-input-label {
  display: inline-block;
}

.upload-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: #ff4b8b;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s;
  cursor: pointer;
}

.upload-button:hover {
  background: #e6437d;
}

.upload-icon {
  width: 48px;
  height: 48px;
  stroke: #ff4b8b;
  stroke-width: 2;
}

.upload-hint {
  color: #718096;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.how-it-works {
  margin-top: 4rem;
  padding: clamp(1rem, 3vw, 2rem);
}

.how-it-works h2 {
  font-size: clamp(1.3rem, 3.5vw, 1.8rem);
  text-align: center;
  margin-bottom: 2rem;
}

.steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: clamp(1rem, 3vw, 2rem);
  max-width: 1000px;
  margin: 0 auto;
}

.step {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: white;
  padding: clamp(0.8rem, 2vw, 1.2rem);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.step-number {
  width: clamp(2rem, 6vw, 2.5rem);
  height: clamp(2rem, 6vw, 2.5rem);
  background: #1a73e8;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: clamp(1rem, 3vw, 1.2rem);
}

.step p {
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  margin: 0;
}

.workspace {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.main-content {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.love-note {
  margin-bottom: 2rem;
  animation: float 3s ease-in-out infinite;
}

.heart-icon {
  font-size: 3rem;
  display: block;
  margin-bottom: 0.5rem;
}

.from-jerry {
  font-family: 'Pacifico', cursive;
  color: #ff4b8b;
  font-size: 1.5rem;
}

.personal-message {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: #fff5f8;
  border-radius: 12px;
  border: 2px solid #ffe4ed;
  text-align: left;
  font-size: 1.1rem;
  color: #2d3748;
  line-height: 1.6;
}

.personal-message p:first-child {
  color: #ff4b8b;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.love-footer {
  text-align: center;
  margin-top: 4rem;
  padding: clamp(1rem, 3vw, 2rem);
}

.love-footer p {
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  color: #666;
  margin: 0.5rem 0;
}

.ps {
  font-size: 0.9rem;
  font-style: italic;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .workspace {
    flex-direction: column;
  }

  .main-content {
    height: 60vh;
  }

  .upload-section {
    margin: 1rem auto;
    padding: 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .steps {
    grid-template-columns: 1fr;
  }

  .language-selector {
    top: 0.5rem;
    right: 0.5rem;
  }

  .language-select {
    padding: 0.3rem;
    font-size: 0.8rem;
  }
}

/* Ensure PDF viewer controls are usable on mobile */
@media (max-width: 480px) {
  .floating-analyze-button {
    bottom: 1rem;
    padding: 0.8rem;
    max-width: 90%;
  }

  .analyze-button {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }

  .selected-preview {
    font-size: 0.8rem;
    max-height: 60px;
    overflow-y: auto;
  }
}

/* Add Google Font for romantic text */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.upload-area {
  border: 2px dashed #ff4b8b;
  border-radius: 12px;
  padding: 2rem;
  margin: 2rem 0;
  transition: all 0.3s ease;
  background: #fff;
  cursor: pointer;
}

.upload-area.dragging {
  background: #fff5f8;
  border-color: #e6437d;
  transform: scale(1.02);
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.upload-text {
  font-size: 1.2rem;
  color: #4a5568;
  margin: 0;
}

.upload-text-small {
  font-size: 1rem;
  color: #718096;
  margin: 0;
}

.features-grid {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 2rem;
} 