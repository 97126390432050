.sidebar {
  width: 400px;
  height: 100%;
  border-left: 1px solid #ddd;
  overflow-y: auto;
  background: white;
  padding: 1rem;
}

.sidebar h2 {
  margin: 0 0 1rem 0;
  color: #1a73e8;
  font-size: 1.5rem;
}

.annotations-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.annotation-item {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #eee;
  animation: slideIn 0.3s ease-out;
}

.highlighted-text {
  font-style: italic;
  color: #666;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.page-number {
  font-size: 0.8rem;
  color: #888;
  margin-bottom: 0.5rem;
}

.annotation-note {
  width: 100%;
  min-height: 100px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  resize: vertical;
}

.analysis-content {
  background: white;
  border-radius: 4px;
  padding: 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Markdown styles specific to sidebar */
.sidebar .markdown-content {
  font-size: 0.9rem;
}

.sidebar .markdown-content h1 {
  font-size: 1.3em;
}

.sidebar .markdown-content h2 {
  font-size: 1.2em;
  margin: 1em 0 0.5em 0;
}

.sidebar .markdown-content h3 {
  font-size: 1.1em;
}

.sidebar .markdown-content h4 {
  font-size: 1em;
}

.sidebar .markdown-content p {
  margin: 0.5em 0;
}

.sidebar .markdown-content ul,
.sidebar .markdown-content ol {
  margin: 0.3em 0;
  padding-left: 1.2em;
}

.sidebar .markdown-content li {
  margin: 0.2em 0;
}

.sidebar .markdown-content pre {
  margin: 0.5em 0;
  padding: 0.8em;
  background: #f5f7f9;
  border-radius: 4px;
  overflow-x: auto;
}

.sidebar .markdown-content blockquote {
  margin: 0.5em 0;
  padding-left: 0.8em;
  border-left: 3px solid #1a73e8;
  color: #666;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.follow-up-section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.follow-up-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.follow-up-input:focus {
  outline: none;
  border-color: #ff4b8b;
  box-shadow: 0 0 0 2px rgba(255, 75, 139, 0.1);
}

.follow-up-button {
  background: #ff4b8b;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background 0.2s;
}

.follow-up-button:hover:not(:disabled) {
  background: #e6437d;
}

.follow-up-button:disabled {
  background: #ffb3cb;
  cursor: not-allowed;
}

.follow-up-answer {
  margin-top: 1rem;
  padding: 1rem;
  background: #fff5f8;
  border-radius: 8px;
  border-left: 3px solid #ff4b8b;
}

.follow-up-answer.streaming {
  background: #fff5f8;
  border-left: 3px solid #ff4b8b;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
} 